@import "./colors.scss";
@import "./colors.scss";
$pn-font-path: "../fonts" !default;

@font-face {
  font-family: "Rounded_Elegance";
  src: local("Rounded_Elegance"),
    url("#{$pn-font-path}/rounded_elegance.ttf") format("opentype");
}
.bl--logo {
  height: 6vh;
  @media (max-width: 800px) {
    height: 3vh;
  }
}
.bl--first-block {
  padding-top: 2vh;
  min-height: 40vh;
  background-color: $bl--color-blue;
  align-items: center;
  text-align: center;
}

.ant-layout-content {
  padding: 0px !important;
}
.bl--img-home {
  margin-top: 3vh;
}
.bl--title-home {
  font-size: 36px;
  font-family: "Rounded_Elegance";
  color: #fff;
  font-weight: normal;
  @media (max-width: 800px) {
    font-size: 2.5em;
  }
}
.bl--subtitle-home {
  color: #81cec8;
  font-family: "Rounded_Elegance";
  font-size: 41px;
  @media (max-width: 1400px) {
    font-size: 2em;
  }
}
.bl--subtitle-home-big {
  font-size: 76px;
  @media (max-width: 1400px) {
    font-size: 2em;
  }
}
.bl--background-block {
  background-color: #f5faf9;
}
.bl--home-img {
  width: 80%;
  height: 25vh;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  @media (max-width: 800px) {
    width: 100%;
  }
}
.container {
  position: relative;
}

.image {
  display: block;
  width: 80%;
  height: 25vh;
}
.bl--slider-img {
  width: 90%;
  height: 40vh;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 80%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(95, 180, 195, 0.4);
  border-radius: 10px;
}

.container:hover .overlay {
  opacity: 1;
}

.bl--img-home {
  top: 50%;
  display: block;
  margin-left: auto;
  width: 50vh;
  margin-right: auto;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.ant-layout-header {
  background-color: #fff !important;
}
.ant-menu {
  color: #00738e !important;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold !important;
}
.ant-layout-header {
  line-height: 80px !important;
  height: 80px !important;
}
.bl--title-img {
  text-align: center;
  font-weight: bold;
  font-family: Montserrat;
  color: #9d9d9e;
  font-size: 29px;
  @media (max-width: 1400px) {
    font-size: 1.2em;
  }
}

.bl--subtitle-img {
  text-align: center;
  font-weight: regular;
  font-family: Lato;
  color: #9d9d9e;
  font-size: 19px;
  justify-content: center;
  @media (max-width: 800px) {
    font-size: 1em;
  }
}
.bl--paragraph-service {
  color: #9d9d9e;
  font-family: Lato;
  font-size: 30px;
}
.bl--first-circle {
  height: 25vh;
  width: 25vh;
  background-color: #444644;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin: 2vh;
}
.bl--second-circle {
  height: 25vh;
  width: 25vh;
  background-color: #5fb3c3;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin: 2.5vh;
}
.bl--third-circle {
  color: #5fb3c3;
  border: 1px solid #5fb3c3;
  height: 30vh;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30vh;
}
.bl--bloc-video {
  min-height: 40vh;
  background-color: #444644;
}
.bl--bloc-video-title {
  font-size: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #fff;
}
.bl--bloc-video-paragraph {
  color: #fff;
  line-height: 40px;
  font-size: 26px;
}
.bl--bloc-video-button {
  min-width: 5vh;
  background-color: #5fb3c3 !important;
  border: 1px solid #5fb3c3 !important;
  min-height: 4vh;
  text-transform: uppercase;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px;
  border-radius: 5px !important;
}
.bl--nb-projects {
  font-size: 4.5em;
  font-family: Montserrat;

  @media (max-width: 1400px) {
    font-size: 2em;
  }
}

.bl--projects {
  font-size: 27px;
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
}

.bl--footer-title {
  font-family: Montserrat;
  font-size: 37px;
  color: #fff;
  font-weight: bold;
  position: relative;
}

.bl--footer-title span {
  padding-right: 10px;
}

.bl--footer-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1px solid #fff;
  z-index: -1;
}

h2 {
  font-size: 1rem;
  position: relative;
}

h2 span {
  background-color: white;
  padding-right: 10px;
}

h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1px solid black;
  z-index: -1;
}
.bl--img-footer {
  width: 80%;
  margin-top: 2vh;
}
.bl--paragraph-footer {
  font-family: Montserrat;

  color: #9d9d9e;
  font-size: 20px;
}
.bl--footer-title {
  position: relative;
  overflow: hidden;
  display: block;

  span {
    vertical-align: baseline;
    display: block;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 20%;
      top: 50%;
      margin-left: 10px;
      border-top: 3px solid #fff;
    }
  }
}
.bl--large {
  font-size: 25px;
  font-family: Montserrat;
  @media (max-width: 1400px) {
    font-size: 1.3em;
    margin-top: 15px;
  }
}

.bl--about-title {
  font-family: "Rounded_Elegance";

  font-size: 2em;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.bl--description {
  font-family: "Rounded_Elegance";
  font-size: 1.5em;
  color: #fff;
  line-height: 2em;
  margin-bottom: 0em !important;
  @media (max-width: 800px) {
    font-size: 1.2em;
  }
}
.bl--ul li {
  font-family: "Rounded_Elegance";

  color: #fff;
  font-size: 1.5em;
  line-height: 1.5em;
}

.bl--img-page {
  width: 90%;
  margin-top: 2vh;
  border-radius: 10px;
}
.bl--description-vert {
  font-family: "Rounded_Elegance";
  font-size: 1.5em;
  color: #81cec8;
  line-height: 2em;
  margin-bottom: 0em !important;
  @media (max-width: 800px) {
    font-size: 1.2em;
  }
}
